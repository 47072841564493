import React from 'react'

export const DecorBottom = (props) => {
	return (
		<svg width="38" height="152" viewBox="0 0 38 152" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M19.2711 128.844L7.84795 116.76C7.21532 116.09 6.09091 116.56 6.1218 117.48L6.53434 129.775C6.54022 129.95 6.59203 130.121 6.68458 130.27L18.7604 149.689C19.1472 150.311 20.0494 150.32 20.4483 149.706L33.0845 130.248C33.196 130.076 33.2521 129.874 33.2453 129.669L32.8894 119.062C32.86 118.188 31.8015 117.77 31.1829 118.389L20.7048 128.865C20.3065 129.263 19.658 129.254 19.2711 128.844Z" fill="#911705" stroke="#8A1704"/>
		<path d="M21.5137 52.6087L21.6463 24.6426C21.648 24.2905 21.8347 23.9652 22.138 23.7862L31.6809 18.1533C32.3512 17.7577 33.1966 18.2457 33.1892 19.0239L32.9516 44.2321C32.949 44.5144 32.8271 44.7824 32.6161 44.97L23.1781 53.3608C22.5315 53.9356 21.5096 53.4738 21.5137 52.6087Z" fill="#911705" stroke="#8A1704"/>
		<path d="M18.1082 53.0127L18.0795 25.0388C18.0792 24.6845 17.8935 24.343 17.5911 24.1403L8.05967 17.7518C7.39454 17.306 6.55472 17.7301 6.55916 18.5095L6.70302 43.7308C6.70464 44.0153 6.82593 44.2946 7.03668 44.4992L16.4529 53.6403C17.0946 54.2632 18.1091 53.8786 18.1082 53.0127Z" fill="#911705" stroke="#8A1704"/>
		<path d="M18.0645 86.8359L18.0358 58.862C18.0355 58.5078 17.8498 58.1662 17.5474 57.9636L8.01597 51.575C7.35084 51.1292 6.51101 51.5533 6.51546 52.3327L6.65932 77.5541C6.66094 77.8386 6.78222 78.1179 6.99298 78.3225L16.4092 87.4635C17.0509 88.0865 18.0654 87.7018 18.0645 86.8359Z" fill="#911705" stroke="#8A1704"/>
		<path d="M17.6089 120.109L17.5803 92.1354C17.5799 91.7812 17.3942 91.4397 17.0918 91.237L7.5604 84.8485C6.89527 84.4027 6.05545 84.8267 6.05989 85.6061L6.20375 110.828C6.20538 111.112 6.32666 111.391 6.53741 111.596L15.9537 120.737C16.5953 121.36 17.6098 120.975 17.6089 120.109Z" fill="#911705" stroke="#8A1704"/>
		<path d="M21.6946 87.271L21.8272 59.3049C21.8289 58.9528 22.0156 58.6275 22.3189 58.4485L31.8619 52.8157C32.5321 52.4201 33.3775 52.908 33.3701 53.6863L33.1325 78.8944C33.1299 79.1767 33.008 79.4448 32.797 79.6323L23.359 88.0231C22.7124 88.5979 21.6905 88.1362 21.6946 87.271Z" fill="#911705" stroke="#8A1704"/>
		<path d="M21.6321 120.531L21.7647 92.5647C21.7664 92.2126 21.9531 91.8873 22.2564 91.7083L31.7994 86.0755C32.4696 85.6799 33.315 86.1678 33.3076 86.9461L33.07 112.154C33.0674 112.436 32.9455 112.705 32.7345 112.892L23.2965 121.283C22.6499 121.858 21.628 121.396 21.6321 120.531Z" fill="#911705" stroke="#8A1704"/>
		<rect width="4.2927" height="26.2637" rx="2.14635" transform="matrix(0.806651 -0.591028 0.346261 0.938138 5.17358 -4.46863)" fill="#911705"/>
		<rect width="3.92726" height="27.603" rx="1.96363" transform="matrix(0.999989 -0.00466048 0.019783 0.999804 17.1909 -8.98425)" fill="#911705"/>
		<rect width="4.22997" height="26.5068" rx="2.11498" transform="matrix(0.871893 0.489697 -0.250446 0.968131 28.2781 -7.66492)" fill="#911705"/>
		</svg>
	)
}
