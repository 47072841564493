import React from 'react'

export const DecorTop = (props) => {
	return (
		<svg width="38" height="144" viewBox="0 0 38 144" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.5126 22.6467L29.9128 34.753C30.5441 35.4235 31.6694 34.9563 31.6403 34.0358L31.251 21.74C31.2455 21.5649 31.194 21.3942 31.1017 21.2452L19.0627 1.80304C18.6771 1.18032 17.775 1.16976 17.3749 1.78328L4.70181 21.2174C4.58996 21.3889 4.53347 21.5906 4.53994 21.7953L4.87576 32.403C4.90344 33.2773 5.96116 33.6972 6.58095 33.0798L17.0788 22.6237C17.4779 22.2263 18.1264 22.2366 18.5126 22.6467Z" fill="#911705" stroke="#8A1704"/>
		<path d="M16.1255 98.8781L15.9399 126.844C15.9375 127.196 15.7502 127.521 15.4466 127.699L5.89295 133.314C5.22196 133.708 4.37753 133.219 4.38634 132.441L4.67168 107.233C4.67487 106.951 4.79725 106.683 5.0086 106.496L14.4625 98.1228C15.1101 97.5492 16.1312 98.0129 16.1255 98.8781Z" fill="#911705" stroke="#8A1704"/>
		<path d="M19.532 98.4804L19.5077 126.454C19.5074 126.809 19.6924 127.15 19.9944 127.354L29.5137 133.76C30.178 134.207 31.0186 133.785 31.0156 133.005L30.9196 107.784C30.9185 107.499 30.7977 107.22 30.5874 107.015L21.1884 97.856C20.548 97.2318 19.5327 97.6146 19.532 98.4804Z" fill="#911705" stroke="#8A1704"/>
		<path d="M19.6395 64.6574L19.6152 92.6313C19.6149 92.9856 19.7999 93.3274 20.1019 93.5307L29.6212 99.9372C30.2855 100.384 31.1262 99.9618 31.1232 99.1824L31.0271 73.9608C31.026 73.6763 30.9053 73.3968 30.6949 73.1918L21.296 64.0329C20.6555 63.4088 19.6403 63.7915 19.6395 64.6574Z" fill="#911705" stroke="#8A1704"/>
		<path d="M20.1581 31.3848L20.1338 59.3588C20.1335 59.713 20.3185 60.0549 20.6205 60.2581L30.1398 66.6647C30.8041 67.1118 31.6447 66.6893 31.6417 65.9099L31.5457 40.6883C31.5446 40.4038 31.4238 40.1243 31.2135 39.9193L21.8145 30.7604C21.1741 30.1362 20.1588 30.519 20.1581 31.3848Z" fill="#911705" stroke="#8A1704"/>
		<path d="M16.0105 64.2154L15.8249 92.1812C15.8225 92.5333 15.6352 92.8582 15.3316 93.0367L5.77796 98.6514C5.10697 99.0457 4.26254 98.5562 4.27135 97.7779L4.55669 72.5703C4.55988 72.288 4.68226 72.0202 4.89361 71.833L14.3475 63.4601C14.9951 62.8865 16.0162 63.3503 16.0105 64.2154Z" fill="#911705" stroke="#8A1704"/>
		<path d="M16.1359 30.9558L15.9502 58.9216C15.9479 59.2737 15.7605 59.5987 15.457 59.7771L5.90333 65.3918C5.23234 65.7862 4.3879 65.2966 4.39671 64.5184L4.68205 39.3107C4.68525 39.0284 4.80763 38.7606 5.01898 38.5735L14.4729 30.2006C15.1205 29.627 16.1416 30.0907 16.1359 30.9558Z" fill="#911705" stroke="#8A1704"/>
		<rect width="4.2927" height="26.2637" rx="2.14635" transform="matrix(-0.80777 0.589499 -0.344483 -0.938792 32.3575 155.986)" fill="#911705"/>
		<rect width="3.92726" height="27.603" rx="1.96363" transform="matrix(-0.999996 0.00276623 -0.0178891 -0.99984 20.3317 160.479)" fill="#911705"/>
		<rect width="4.22997" height="26.5068" rx="2.11498" transform="matrix(-0.870964 -0.491347 0.252279 -0.967655 9.24707 159.139)" fill="#911705"/>
		</svg>
	)
}
