import React from 'react'

export const Tell = (props) => {
	return (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M30.6666 9.33325V1.33325H22.6666" stroke="#FF7A32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M21.3334 10.6666L30.6667 1.33325" stroke="#FF7A32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		<path d="M29.3333 22.56V26.56C29.3348 26.9313 29.2587 27.2989 29.11 27.6391C28.9612 27.9793 28.743 28.2848 28.4694 28.5358C28.1958 28.7868 27.8727 28.9779 27.521 29.0969C27.1692 29.2159 26.7964 29.2601 26.4266 29.2266C22.3237 28.7808 18.3826 27.3788 14.92 25.1333C11.6984 23.0862 8.96707 20.3549 6.91995 17.1333C4.66659 13.6549 3.26428 9.69463 2.82662 5.5733C2.7933 5.20459 2.83712 4.83298 2.95529 4.48213C3.07345 4.13128 3.26338 3.80889 3.51297 3.53546C3.76257 3.26204 4.06636 3.04358 4.40501 2.894C4.74365 2.74441 5.10974 2.66698 5.47995 2.66663H9.47995C10.127 2.66026 10.7543 2.8894 11.245 3.31134C11.7356 3.73328 12.0561 4.31923 12.1466 4.95997C12.3154 6.24006 12.6286 7.49694 13.08 8.70663C13.2593 9.18387 13.2982 9.70252 13.1918 10.2011C13.0855 10.6998 12.8384 11.1574 12.48 11.52L10.7866 13.2133C12.6847 16.5514 15.4486 19.3152 18.7866 21.2133L20.48 19.52C20.8425 19.1615 21.3002 18.9144 21.7988 18.8081C22.2974 18.7018 22.8161 18.7406 23.2933 18.92C24.503 19.3714 25.7599 19.6845 27.04 19.8533C27.6876 19.9447 28.2792 20.2709 28.702 20.77C29.1248 21.269 29.3495 21.9061 29.3333 22.56Z" stroke="#FF7A32" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
	)
}
