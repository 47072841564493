
import css from './styles.module.scss';

export const Info = () => {
  return <div className={css.info}>
    <h2>Чому варто придбати нашу зерносушарку?</h2>
    <ul>
      <li>
        <p>
          Зерносушарка мобільна бункерна - це пристрій, призначений для сушіння сільськогосподарських культур, який може працювати на різних видів палива. Вона має мобільну конструкцію та бункер для зберігання продукту. Ця зерносушарка дозволяє сільськогосподарським виробникам сушити свої урожаї зручно та ефективно, використовуючи різні види палива в залежності від доступності та вибору.
        </p>
      </li>
      <li>
        <p>
          Зерносушарки – це справжня знахідка для фермерів. Вони виконують важливу роль у збереженні зернових культур, роблячи їх готовими для тривалого зберігання та подальшого використання. Основна мета цих пристроїв – зменшення вологості в зерні, що не тільки покращує його якість, але й запобігає виникненню проблем зі зберіганням.
        </p>
      </li>
      <li>
        <p>
          Нехай ваш врожай буде завжди свіжим та якісним з нашими мобільними зерносушарками. Збережіть врожай, збережіть якість, збережіть успіх на вашій фермі!
        </p>
      </li>
    </ul>
  </div>
}
